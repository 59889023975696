body {
  padding: 0;
  margin: 0;
  background-color: #efefef;
  color: #EF3C33;
  font-family: bebas-neue-pro, sans-serif;
}

h1 {
  font-weight: 600;
  font-style: normal;
  font-size: 62px;
  margin-top: 0px;
}

h2 {
  font-size: 40px;
  font-weight: 600;
}

h3 {
  font-weight: 700;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #000000;
}

h4 {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  margin: 0px;
  color: #5F5F5F;
}

hr {
  border-top: 3px solid #EF3C33;
}

a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
}

.container {
  width: 90%;
  margin: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.contents-wrapper {
  width: 100%;
}

header {
  font-size: 16px;
  z-index: 99;
  width: 100%;
  margin: 40px 0px;
}
header .logo {
  width: 250px;
}

.list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2%;
}

.thumbnails {
  text-align: center;
  width: 31.3333333333%;
  padding-bottom: 60px;
}

.thumbnailImage {
  max-width: 100%;
}

.thumbnails:hover h3 {
  color: #EF3C33;
  transition: all 0.25s 0s ease;
}

.hero-img {
  width: 100%;
  margin-bottom: -3.5px;
}

.huge-img {
  width: 100%;
  padding-bottom: 50px;
}

.section-wrapper {
  margin-top: 150px;
}
.section-wrapper .double-img {
  display: flex;
  width: 100%;
}
.section-wrapper .double-img .huge-img {
  max-width: 50%;
}

.work-end hr {
  margin: 0px;
  border-top: 5px solid #EF3C33;
}
.work-end .arrow-and-text {
  width: 300px;
  padding-top: 25px;
  padding-bottom: 60px;
}
.work-end .arrow-and-text .st0 {
  fill: #a4a4a4;
}
.work-end .arrow-and-text .st0:hover {
  fill: #EF3C33;
  transition: all 0.45s 0s ease;
}

.work-contents {
  background-color: #ffffff;
  margin-top: 0px;
  padding: 110px 10% 80px;
}
.work-contents h1 {
  color: #000000;
  margin-bottom: 22px;
}
.work-contents .info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.work-contents p {
  color: #000000;
  line-height: 33px;
  width: 60%;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}

.about-detail-page .back-ground {
  background-color: #ffffff;
}
.about-detail-page .back-ground .profile-contents {
  margin-top: 0px;
  padding: 110px 80px;
  gap: 80px;
  display: flex;
}
.about-detail-page .back-ground .images {
  width: 40%;
}
.about-detail-page .back-ground .text {
  width: 60%;
  color: #121212;
}
.about-detail-page .back-ground .text p {
  padding-top: 0px;
  padding-bottom: 20px;
  line-height: 33px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}
.about-detail-page .back-ground .text .profile-section {
  padding-bottom: 90px;
}
.about-detail-page .back-ground .text .profile-section .name {
  padding-bottom: 20px;
}
.about-detail-page .back-ground .text .profile-section .name h2 {
  margin: 0px;
  padding-bottom: 0px;
  font-weight: 100;
}
.about-detail-page .back-ground .text .profile-section .name p {
  margin: 0px;
  padding-bottom: 0px;
  font-size: 21px;
  color: #676767;
}
.about-detail-page .back-ground .text .profile-section .sns {
  display: flex;
  gap: 80px;
}
.about-detail-page .back-ground .text .profile-section .sns a {
  width: 0px;
}
.about-detail-page .back-ground .text .profile-section .sns .sns-icon {
  fill: #efefef;
}
.about-detail-page .back-ground .text .profile-section .sns .sns-icon:hover {
  fill: #EF3C33;
  transition: all 0.25s 0s ease;
}
.about-detail-page .back-ground .text .career-section {
  text-align: left;
  line-height: 33px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}
.about-detail-page .back-ground .text .career-section th {
  padding-right: 30px;
  font-weight: 400;
  vertical-align: top;
}

.footer {
  background-color: #EF3C33;
  color: #ffffff;
  margin-top: 40px;
  padding-top: 2px;
  padding-bottom: 2px;
}

@media (max-width: 1200px) {
  .thumbnails {
    width: 48%;
    padding-bottom: 60px;
  }
  .work-contents p {
    width: 100%;
  }
  .section-wrapper .double-img {
    display: block;
    width: 100%;
  }
  .section-wrapper .double-img .huge-img {
    max-width: 100%;
  }
  .about-detail-page .back-ground {
    background-color: #ffffff;
  }
  .about-detail-page .back-ground .profile-contents {
    display: block;
  }
  .about-detail-page .back-ground .images {
    width: 100%;
    padding-bottom: 80px;
  }
  .about-detail-page .back-ground .text {
    width: 100%;
  }
}
@media (max-width: 760px) {
  .thumbnails {
    width: 100%;
    padding-bottom: 60px;
  }
  header .logo {
    width: 200px;
  }
  h1 {
    font-size: 48px;
  }
  h3 {
    font-size: 28px;
  }
  .huge-img {
    padding-bottom: 8px;
  }
  .work-contents {
    padding: 60px 5% 80px;
  }
  .work-contents p {
    width: 100%;
    font-size: 15px;
  }
  .work-contents h3 {
    font-size: 21px;
  }
  .work-contents h4 {
    margin-bottom: 10px;
  }
  .work-contents .title-section {
    margin-bottom: 60px;
  }
  .work-contents .info {
    display: inline;
  }
  .section-wrapper {
    margin-top: 100px;
  }
  .section-wrapper .double-img {
    display: block;
    width: 100%;
  }
  .section-wrapper .double-img .huge-img {
    max-width: 100%;
  }
  .about-detail-page h2 {
    font-size: 34px;
  }
  .about-detail-page .back-ground {
    background-color: #ffffff;
  }
  .about-detail-page .back-ground .profile-contents {
    display: block;
    padding: 40px 5%;
  }
  .about-detail-page .back-ground .images {
    width: 100%;
    padding-bottom: 80px;
  }
  .about-detail-page .back-ground .text {
    width: 100%;
  }
  .about-detail-page .back-ground .text .career-section th {
    padding-right: 0px;
    font-weight: 400;
    padding-bottom: 15px;
  }
  .about-detail-page .back-ground .text .career-section .right {
    width: 30%;
  }
  .work-end .arrow-and-text {
    width: 200px;
    padding-bottom: 40px;
  }
}/*# sourceMappingURL=App.css.map */