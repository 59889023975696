@charset "UTF-8";

//color palette
$primary: #EF3C33;
$gray: #efefef;
$dark-gray: #5F5F5F;
$white: #ffffff;
$black: #000000;

//media
$tab: 1200px; // タブレット
$sp: 760px;  // スマホ

//wrapper
$main-wrapper : 90%;

body {
    padding: 0;
    margin: 0;
    background-color: $gray;
    color: $primary;
    font-family: bebas-neue-pro, sans-serif;
}

h1{
    font-weight: 600;
    font-style: normal;
    font-size: 62px;
    margin-top: 0px;
}

h2{
    font-size: 40px;
    font-weight: 600;
}

h3{
    font-weight: 700;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: $black;
}

h4{
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    margin: 0px;
    color: $dark-gray;
}

hr{
    border-top: 3px solid $primary;
}

a{
    display:inline-block;
    width: 100%;
    text-decoration: none;
}

.container {
    width: $main-wrapper;
    margin: auto;
}

.overflow-hidden{
    overflow: hidden;
}

.contents-wrapper{
    width: 100%;
}

header {
  font-size: 16px;
  z-index: 99;
  width: 100%;
  margin: 40px 0px;

  .logo{
    width: 250px;
  }
}

.list-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2%;
}

.thumbnails{
    text-align: center;
    width: calc(100%/3 - 2%);
    padding-bottom: 60px;
}


.thumbnailImage{
    max-width: 100%;
}

// .thumbnails:hover .thumbnailImage {
//     border: 4px solid $primary;
//     outline-offset: -8px;
//     transition: all 0.25s 0s ease;
// }

.thumbnails:hover h3 {
    color: #EF3C33;
    transition: all 0.25s 0s ease;
}

.hero-img{
    width: 100%;
    margin-bottom: -3.5px;
}

.huge-img{
    width: 100%;
    padding-bottom: 50px;
}


.section-wrapper{
    margin-top: 150px;

    .double-img{
        display: flex;
        width: 100%;

        .huge-img{
            max-width: 50%;
        }
    }
}

.work-end{

    hr{
        margin: 0px;
        border-top: 5px solid $primary;
    }

    .arrow-and-text{

        width: 300px;
        padding-top: 25px;
        padding-bottom: 60px;
    
        .st0 {
            fill: #a4a4a4;

            &:hover{
                fill: $primary;
                transition: all 0.45s 0s ease;

            }
        }
    }
    
}


.work-contents{
    background-color: $white;
    margin-top: 0px;
    padding: 110px 10% 80px;

    h1{
        color: $black;
        margin-bottom: 22px;
    }

    .info{
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    p{
        color: $black;
        line-height:33px;
        width: 60%;
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 16px;
    }
}

.about-detail-page{

    .back-ground{
        background-color: $white;      

        .profile-contents{
            margin-top: 0px;
            padding: 110px  80px;
            gap: 80px;
            display: flex;
        }

        .images{
            width: 40%;
        }

        .text{
            width: 60%;
            color: #121212;

            p{
                padding-top: 0px;
                padding-bottom: 20px;
                line-height:33px;
                font-family: 'Noto Sans JP', sans-serif;
                font-size: 16px;
            }

            .profile-section {
                padding-bottom: 90px;

                
    
                .name{
                    padding-bottom: 20px;
    
                    h2{
                        margin: 0px;
                        padding-bottom: 0px;
                        font-weight: 100;
                    }
    
                    p{
                        margin: 0px;
                        padding-bottom: 0px;
                        font-size: 21px;
                        color:#676767;
                    }
                }

                .sns{
                    display: flex;
                    gap: 80px;

                    a{
                        width: 0px;
                    }

                    .sns-icon{
                        fill: $gray;

                        &:hover{
                            fill: $primary;
                            transition: all 0.25s 0s ease;
                        }

                    }
                }
            }

            .career-section{
                text-align: left;
                line-height:33px;
                font-family: 'Noto Sans JP', sans-serif;
                font-size: 16px;
                

                th{
                    padding-right: 30px;
                    font-weight: 400;
                    vertical-align: top;
                }
            }


        }
    }
}

.footer{
    background-color: $primary;
    color: $white;
    margin-top: 40px;
    padding-top: 2px;
    padding-bottom: 2px;
}



@media (max-width: ($tab)) {
    .thumbnails{
        width: calc(100%/2 - 2%);
        padding-bottom: 60px;
    }

    .work-contents{
    
        p{
            width: 100%;
        }
    }

    .section-wrapper{
    
        .double-img{
            display:block;
            width: 100%;
    
            .huge-img{
                max-width: 100%;
            }
        }
    }


    .about-detail-page{

        .back-ground{
            background-color: $white;      
    
            .profile-contents{
                display: block;
            }
    
            .images{
                width: 100%;
                padding-bottom: 80px;
            }

            .text{
                width: 100%;
            }
    
            
        }
    }
}


@media (max-width: ($sp)) {
    .thumbnails{
        width: 100%;
        padding-bottom: 60px;
    }

    header {      
        .logo{
          width: 200px;
        }
      }

      h1{
        font-size: 48px;
      }

      h3{
        font-size: 28px;
        
      }

      .huge-img {
        padding-bottom: 8px;
      }

    .work-contents{
        padding: 60px 5% 80px;

        p{
            width: 100%;
            font-size: 15px;
        }

        h3{
            font-size: 21px;
        }

        h4{
            margin-bottom: 10px;
        }

        .title-section{
            margin-bottom: 60px;
        }

        .info{
            display: inline;
        }
    }

    .section-wrapper{

        margin-top: 100px;
    
        .double-img{
            display:block;
            width: 100%;
    
            .huge-img{
                max-width: 100%;
            }
        }
    }


    .about-detail-page{

        h2{
            font-size: 34px;
        }
        .back-ground{
            background-color: $white;      
    
            .profile-contents{
                display: block;
                padding: 40px  5%;
            }
    
            .images{
                width: 100%;
                padding-bottom: 80px;
            }

            .text{
                width: 100%;

                .career-section{

                    th{
                        padding-right: 0px;
                        font-weight: 400;
                        padding-bottom: 15px;
                    }
                    .right {
                        width: 30%;
                    }
                }
            }

            
    
            
        }
    }

    .work-end{

        .arrow-and-text{
    
            width: 200px;
            padding-bottom: 40px;
        
        }
        
    }
}




